import Components from 'formiojs/components/Components';
import Formio from 'formiojs/Formio';

const AddressComponent = (Components as any).components.address;

class AddressField extends (AddressComponent as any) {
    initializeProvider(provider: any, options: any = {}) {
        if (provider) {
            if (this.currentForm?.submissionSet) {
                const optionsParams = options?.params;

                const calculatedKey = this.interpolate(optionsParams.key, {
                    data: this.root.data,
                    row: this.root.data,
                });

                const transformedOptions = {
                    params: {
                        ...optionsParams,
                        key: calculatedKey,
                    },
                };
                const url = this.interpolate(options.url);
                const Provider = Formio.Providers.getProvider(
                    'address',
                    provider,
                );

                return new Provider({ ...transformedOptions, url });
            }
            setTimeout(() => {
                this.redraw();
            }, 0);
        }
    }
}

export default AddressField;
