/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-param-reassign */
/* eslint-disable complexity */
import { Utils } from 'formiojs';
import { customComponents } from 'hes-formio-components';
import _ from 'lodash';

const Pagination = (customComponents as any).paginationComponent;

class PaginationComponent extends (Pagination as any) {
    get paginatedDataList() {
        const paginatedDataComponent = (Utils as any).findComponents(
            (this as any).root.components,
            {
                key: (this as any).component.dataSourcePath
                    ?.split('.')
                    ?.reverse()?.[0],
            },
        );

        return paginatedDataComponent?.[0]?.getValue();
    }

    getInitialPaginatedData() {
        return (this as any).component.dataSourcePath
            ? this.paginateArray(
                  this.paginatedDataList,
                  1,
                  (this as any).component.itemsPerPage,
              )
            : [];
    }

    setNewPageFetchedValue() {
        if ((this as any).component.paginatedDataSourcePath) {
            const paginatedListComponent = (Utils as any).getComponent(
                (this as any).components,
                'paginatedList',
            );
            const paginatedList = _.get(
                (this as any).rootData,
                (this as any).component.paginatedDataSourcePath,
            );
            paginatedListComponent.setValue(paginatedList);
        }
    }

    getPagesNum() {
        const totalElementsValue =
            _.get(
                this.root.data,
                (this as any).component.totalElementsValuePath,
            ) || this.paginatedDataList?.length;

        return Math.ceil(
            totalElementsValue / (this as any).component.itemsPerPage,
        );
    }

    setNewPageValue(nextPage: number) {
        if ((this as any).component.dataSourcePath) {
            const paginatedListComponent = (Utils as any).getComponent(
                (this as any).components,
                'paginatedList',
            );
            const dataList = (this as any)?.paginatedDataList;
            const paginatedList = this.paginateArray(
                dataList,
                nextPage,
                (this as any).component.itemsPerPage,
            );
            paginatedListComponent?.setValue(paginatedList);
        }
        const currentPageComponent = (Utils as any).getComponent(
            (this as any).components,
            'currentPage',
        );
        currentPageComponent?.setValue(nextPage);
    }

    attach(element: string) {
        (this as any).loadRefs(element, {
            nextPageButton: 'single',
            previousPageButton: 'single',
            sizeBtns: 'single',
            buttonPage: 'multiple',
            sizeBtn: 'multiple',
        });

        this.interval = setTimeout(() => {
            const initialValue = {
                dataList: this.paginatedDataList,
                paginatedList: this.getInitialPaginatedData(),
                currentPage: (this as any).component.isStartPageZero ? 0 : 1,
                itemsPerPage:
                    (this as any).getValue()?.itemsPerPage ||
                    (this as any).component.itemsPerPage ||
                    10,
                totalElements:
                    _.get(
                        this.root.data,
                        (this as any).component.totalElementsValuePath,
                    ) || this.paginatedDataList?.length,
                totalPages: this.getPagesNum(),
            };

            const pageForSetInitialValue = (this as any).component
                .isStartPageZero
                ? 1
                : 2;

            if (
                (this as any).getValue().currentPage < pageForSetInitialValue &&
                !(this as any).builderMode
            ) {
                this.setValue(initialValue);
            }
        }, 0);

        return super.attach(element);
    }
}

export default PaginationComponent;
